// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.background {
  width: 100%;
  height: 100%;
  background: linear-gradient(148deg, #2a005d, #5d0086);
}

.app {
  max-width: calc(100% - 80px);
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin: 0 auto;
  padding-inline: 24px;
  color: white;
}
@media screen and (max-width: 968px) {
  .app {
    max-width: 100%;
  }
}
@media screen and (max-width: 400px) {
  .app {
    padding-inline: 16px;
  }
}

.content {
  width: 100%;
  flex: 1 0 auto;
  display: flex;
  align-items: center;
  flex-direction: column;
  flex-wrap: nowrap;
  margin: 0 auto;
}`, "",{"version":3,"sources":["webpack://./src/App/App.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;EACA,YAAA;EACA,qDAAA;AACF;;AACA;EACE,4BAAA;EACA,WAAA;EACA,YAAA;EACA,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,sBAAA;EACA,cAAA;EACA,oBAAA;EACA,YAAA;AAEF;AADE;EAXF;IAYI,eAAA;EAIF;AACF;AAHE;EAdF;IAeI,oBAAA;EAMF;AACF;;AAJA;EACE,WAAA;EACA,cAAA;EACA,aAAA;EACA,mBAAA;EACA,sBAAA;EACA,iBAAA;EACA,cAAA;AAOF","sourcesContent":[".background {\n  width: 100%;\n  height: 100%;\n  background: linear-gradient(148deg, #2a005d, #5d0086);\n}\n.app {\n  max-width: calc(100% - 80px);\n  width: 100%;\n  height: 100%;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  flex-direction: column;\n  margin: 0 auto;\n  padding-inline: 24px;\n  color: white;\n  @media screen and (max-width: 968px) {\n    max-width: 100%;\n  }\n  @media screen and (max-width: 400px) {\n    padding-inline: 16px;\n  }\n}\n.content {\n  width: 100%;\n  flex: 1 0 auto;\n  display: flex;\n  align-items: center;\n  flex-direction: column;\n  flex-wrap: nowrap;\n  margin: 0 auto;\n  \n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
