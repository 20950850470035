// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.footer-container {
  width: 100%;
  flex: 0 0 auto;
  padding: 12px;
}
.footer-container .footer {
  width: 100%;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 10px;
  font-size: 14px;
  color: #ae6dd5;
}
.footer-container .footer img {
  width: 20px;
  height: 20px;
}
@media screen and (max-width: 968px) {
  .footer-container .footer {
    gap: 5px;
    font-size: 12px;
  }
}
.footer-container .footer .links {
  width: 100%;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
}`, "",{"version":3,"sources":["webpack://./src/App/Components/Footer/Footer.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;EACA,cAAA;EACA,aAAA;AACF;AACE;EACE,WAAA;EACA,cAAA;EACA,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,sBAAA;EACA,SAAA;EACA,eAAA;EACA,cAAA;AACJ;AAAI;EACE,WAAA;EACA,YAAA;AAEN;AAAI;EAdF;IAeI,QAAA;IACA,eAAA;EAGJ;AACF;AAFI;EACE,WAAA;EACA,cAAA;EACA,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,SAAA;AAIN","sourcesContent":[".footer-container {\n  width: 100%;\n  flex: 0 0 auto;\n  padding: 12px;\n\n  .footer {\n    width: 100%;\n    margin: 0 auto;\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    flex-direction: column;\n    gap: 10px;\n    font-size: 14px;\n    color: #ae6dd5;\n    img {\n      width: 20px;\n      height: 20px;\n    }\n    @media screen and (max-width: 968px) {\n      gap: 5px;\n      font-size: 12px;\n    }\n    .links {\n      width: 100%;\n      margin: 0 auto;\n      display: flex;\n      align-items: center;\n      justify-content: center;\n      gap: 10px;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
