// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("./fonts/Zelda.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("./fonts/Monocraft.otf", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@font-face {
  font-family: "Zelda";
  font-style: normal;
  font-weight: 400;
  src: url(${___CSS_LOADER_URL_REPLACEMENT_0___}) format("truetype");
}
@font-face {
  font-family: "Monocraft";
  font-style: normal;
  font-weight: 500;
  src: url(${___CSS_LOADER_URL_REPLACEMENT_1___}) format("opentype");
}
* {
  box-sizing: border-box;
  font-family: Monocraft, Zelda, Courier, sans-serif;
}

body {
  margin: 0;
  padding: 0;
}

html,
body,
#root {
  height: 100%;
  overflow: hidden;
}`, "",{"version":3,"sources":["webpack://./src/index.scss"],"names":[],"mappings":"AAAA;EACE,oBAAA;EACA,kBAAA;EACA,gBAAA;EACA,+DAAA;AACF;AAEA;EACE,wBAAA;EACA,kBAAA;EACA,gBAAA;EACA,+DAAA;AAAF;AAGA;EACE,sBAAA;EACA,kDAAA;AADF;;AAIA;EACE,SAAA;EACA,UAAA;AADF;;AAIA;;;EAGE,YAAA;EACA,gBAAA;AADF","sourcesContent":["@font-face {\n  font-family: 'Zelda';\n  font-style: normal;\n  font-weight: 400;\n  src: url('./fonts/Zelda.ttf') format('truetype');\n}\n\n@font-face {\n  font-family: 'Monocraft';\n  font-style: normal;\n  font-weight: 500;\n  src: url('./fonts/Monocraft.otf') format('opentype');\n}\n\n* {\n  box-sizing: border-box;\n  font-family: Monocraft, Zelda, Courier, sans-serif;\n}\n\nbody {\n  margin: 0;\n  padding: 0;\n}\n\nhtml,\nbody,\n#root {\n  height: 100%;\n  overflow: hidden;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
