// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.header-container {
  padding: 48px 0;
}
.header-container .header {
  font-size: 110px;
  color: rgb(255, 205, 0);
  text-shadow: 2px 0px 0px white, 9px 0px 0px #7f0077, 0px 0px 20px #00d6ff;
  font-family: Zelda, Monocraft, Courier, sans-serif;
}

@media screen and (max-width: 968px) {
  .header-container {
    padding: 60px 0;
    text-align: center;
  }
  .header-container .header {
    font-size: 44px;
    text-shadow: 1px 0px 0px white, 5px 0px 0px #7f0077, 0px 0px 20px #00d6ff;
  }
}`, "",{"version":3,"sources":["webpack://./src/App/Components/Header/Header.scss"],"names":[],"mappings":"AAAA;EACE,eAAA;AACF;AAAE;EACE,gBAAA;EACA,uBAAA;EACA,yEAAA;EACA,kDAAA;AAEJ;;AAGA;EACE;IACE,eAAA;IACA,kBAAA;EAAF;EACE;IACE,eAAA;IACA,yEAAA;EACJ;AACF","sourcesContent":[".header-container {\n  padding: 48px 0;\n  .header {\n    font-size: 110px;\n    color: rgb(255 205 0);\n    text-shadow: 2px 0px 0px white, 9px 0px 0px #7f0077, 0px 0px 20px #00d6ff;\n    font-family: Zelda, Monocraft, Courier, sans-serif;\n  }\n}\n\n//mob\n@media screen and (max-width: 968px) {\n  .header-container {\n    padding: 60px 0;\n    text-align: center;\n    .header {\n      font-size: 44px;\n      text-shadow: 1px 0px 0px white, 5px 0px 0px #7f0077, 0px 0px 20px #00d6ff;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
