import React from 'react'
import './Header.scss'

export default function Header() {
  return (
    <div className='header-container'>
      <span className='header'>
        Две Шкатулки
      </span>
    </div>
  )
}
