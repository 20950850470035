export function getGift() {
  const images = [
    { url: "airplane.png", name: "Уникальный самолет!" },
    { url: "bag.png", name: "Великолепная сумка!" },
    { url: "baggage.png", name: "Шикарнаый чемодан!" },
    { url: "baggage2.png", name: "Потрясающий чемодан!" },
    { url: "bball.png", name: "Мой любимый баскетбольный мяч!" },
    { url: "bike.png", name: "Замечательный мотоцикл!" },
    { url: "bottle.png", name: "Милый кораблик в бутылке!" },
    { url: "car.png", name: "АААААААААВТОМОБИЛЬ!" },
    { url: "cat2.png", name: "Замурчательный котик!" },
    { url: "cheese.png", name: "Отличный дырявый сыр!" },
    { url: "cherry.png", name: "Сочная вишенка!" },
    { url: "chicken.png", name: "Аппетитная курица!" },
    { url: "coconut.png", name: "Освежающий кокос!" },
    { url: "coffee.png", name: "Ароматный кофе!" },
    { url: "cookie.png", name: "Шоколадное печенье!" },
    { url: "cowboy.png", name: "Стильная ковбойская шляпа!" },
    { url: "dog2.png", name: "Дружелюбная собачка!" },
    { url: "duck.png", name: "Милейшая уточка!" },
    { url: "frog.png", name: "Лягушка-Квакушка!" },
    { url: "glintvein.png", name: "Согревающий глинтвейн!" },
    { url: "guitar.png", name: "Изумительная гитара!" },
    { url: "hotdog.png", name: "Пленительный хотдог!" },
    { url: "house.png", name: "Завидный загородный дом!" },
    { url: "icecream.png", name: "Прекрасное мороженое!" },
    { url: "key.png", name: "Секртеный ключ!" },
    { url: "lemon.png", name: "Ошеломляющий лимон!" },
    { url: "light.png", name: "Искусный фонарь!" },
    { url: "light2.png", name: "Мощный фонарь!" },
    { url: "mask.png", name: "Сказочная маскарадная маска!" },
    { url: "money.png", name: "Деньги кэш мани бабосики денюжки деньжища!" },
    { url: "octopus.png", name: "Бесподобный осьминог!" },
    { url: "pc.png", name: "Высокотехнологичный компьютер!" },
    { url: "photo.png", name: "Впечатляющий фотоаппарат!" },
    { url: "pickles.png", name: "Превосходная банка головокружительных солёных огурцов!" },
    { url: "pigeon.png", name: "Редкостный голубь!" },
    { url: "ploskogubcy.png", name: "Исключительные плоскогубцы!" },
    { url: "pokeball.png", name: "Эффектный покебол!" },
    { url: "popcorn.png", name: "Феноменальный попкорн!" },
    { url: "pumpkin.png", name: "Роскошная хэллоуинская тыква!" },
    { url: "retroplayer.png", name: "Феерический кассетный плеер!" },
    { url: "sandwich.png", name: "Отпадный сэндвич!" },
    { url: "scooter2.png", name: "Потрясный мопед!" },
    { url: "soda.png", name: "Шипучая газировка!" },
    { url: "submarine.png", name: "Эпохальная желтая субмарина!" },
    { url: "tetris.png", name: "Первоклассный тетрис!" },
    { url: "turtle.png", name: "Почти ниндзя-черепашка!" },
    { url: "uno.png", name: "Победная карточка Уно!" },
    { url: "waterlemon.png", name: "Чудесный арбуз!" }
  ];

  const randomIndex = Math.floor(Math.random() * images.length)
  return images[randomIndex]
}
