// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.overlay-body {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  margin: 0 auto;
  background: linear-gradient(148deg, #2a005d, #5d0086);
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 1;
  transition: opacity 1s ease;
  z-index: 1000;
}

.overlay-body.hidden {
  opacity: 0;
  pointer-events: none;
}

.overlay-text {
  opacity: 0;
  animation: fadeInOut 8500ms forwards;
  color: white;
  text-align: center;
  top: 20%;
  position: absolute;
  display: inline-block;
}
@media screen and (max-width: 968px) {
  .overlay-text {
    top: 30%;
  }
}

.typed-text {
  overflow: hidden;
  font-size: 44px;
  white-space: nowrap;
  width: 0;
  padding: 5px 0;
  animation: typing 2s steps(20, end) forwards;
  animation-delay: 1s;
}
.typed-text.second {
  animation-delay: 3s;
}
@media screen and (max-width: 968px) {
  .typed-text {
    font-size: 16px;
  }
}
@media screen and (max-width: 380px) {
  .typed-text {
    font-size: 14px;
  }
}

@keyframes typing {
  from {
    width: 0;
  }
  to {
    width: 100%;
  }
}
@keyframes fadeInOut {
  0%, 100% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
}`, "",{"version":3,"sources":["webpack://./src/App/Components/Overlay/Overlay.scss"],"names":[],"mappings":"AAAA;EACE,eAAA;EACA,MAAA;EACA,OAAA;EACA,WAAA;EACA,YAAA;EACA,cAAA;EACA,qDAAA;EACA,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,UAAA;EACA,2BAAA;EACA,aAAA;AACF;;AAEA;EACE,UAAA;EACA,oBAAA;AACF;;AAEA;EACE,UAAA;EACA,oCAAA;EACA,YAAA;EACA,kBAAA;EACA,QAAA;EACA,kBAAA;EACA,qBAAA;AACF;AAAE;EARF;IASI,QAAA;EAGF;AACF;;AAAA;EACE,gBAAA;EACA,eAAA;EACA,mBAAA;EACA,QAAA;EACA,cAAA;EACA,4CAAA;EACA,mBAAA;AAGF;AAFE;EACE,mBAAA;AAIJ;AADE;EAZF;IAaI,eAAA;EAIF;AACF;AAHE;EAfF;IAgBI,eAAA;EAMF;AACF;;AAHA;EACE;IACE,QAAA;EAMF;EAJA;IACE,WAAA;EAMF;AACF;AAHA;EACE;IAEE,UAAA;EAIF;EAFA;IACE,UAAA;EAIF;AACF","sourcesContent":[".overlay-body {\n  position: fixed;\n  top: 0;\n  left: 0;\n  width: 100%;\n  height: 100%;\n  margin: 0 auto;\n  background: linear-gradient(148deg, #2a005d, #5d0086);\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  opacity: 1;\n  transition: opacity 1s ease;\n  z-index: 1000;\n}\n\n.overlay-body.hidden {\n  opacity: 0;\n  pointer-events: none;\n}\n\n.overlay-text {\n  opacity: 0;\n  animation: fadeInOut 8500ms forwards;\n  color: white;\n  text-align: center;\n  top: 20%;\n  position: absolute;\n  display: inline-block;\n  @media screen and (max-width: 968px) {\n    top: 30%;\n  }\n}\n\n.typed-text {\n  overflow: hidden;\n  font-size: 44px;\n  white-space: nowrap;\n  width: 0;\n  padding: 5px 0;\n  animation: typing 2s steps(20, end) forwards;\n  animation-delay: 1s;\n  &.second {\n    animation-delay: 3s;\n  }\n\n  @media screen and (max-width: 968px) {\n    font-size: 16px;\n  }\n  @media screen and (max-width: 380px) {\n    font-size: 14px;\n  }\n}\n\n@keyframes typing {\n  from {\n    width: 0;\n  }\n  to {\n    width: 100%;\n  }\n}\n\n@keyframes fadeInOut {\n  0%,\n  100% {\n    opacity: 0;\n  }\n  50% {\n    opacity: 1;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
